body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input[type="checkbox"]:after {
  line-height: 1.5em;
  content: '';
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  margin-top: -3px;
  margin-left: -3px;
  border: 1px solid #953e3e;
  border-radius: 0px;
  background: #fff;
  line-height: 1.3;
}

input[type="checkbox"]:checked:after {
  width: 1.2rem;
  height: 1.2rem;
  display: block;
  content: "\f00c";
  font-family: 'FontAwesome';
  color: #7571f9;
  font-weight: 100;
  text-align: center;
  font-size: 12px;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  line-height: 1.42857143;
  letter-spacing: 0.1px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.customcalendar {
}
.customcalendar .fc-view-harness-active {
  height: calc(100vh - 258px) !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .customcalendar table tbody tr:nth-child(3){ display: none;}
.customcalendar table tbody tr:nth-child(4){ display: none;} */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.required {
  position: relative;
  font-size: 100%;
  color: #b94a48;
}

.required:after {
  content: "*";
  color: #b94a48;
}
.form-actions {
  display: none !important;
}
.btn-close {
  content: "close";
}

.custom-card {
  padding: 0;
}
.custom-card li {
  display: table-cell;
  width: 1%;
  text-align: center;
  padding: 0 15px;
}

.text-black {
  color: #000 !important;
}
.card:before {
  display: none !important;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
body {
  top: 0px !important;
}
.goog-logo-link {
  display: none !important;
}
.trans-section {
  margin: 100px;
}

.box.box-primary {
  border-top-color: #3c8dbc;
}
.box {
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  border-top: 3px solid #d2d6de;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
}
.direct-chat-messages,
.direct-chat-contacts {
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  -moz-transition: -moz-transform 0.5s ease-in-out;
  -o-transition: -o-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
}

.direct-chat-messages {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  padding: 10px;
  /* height: 250px; */
  overflow: auto;
  text-align: center;
  overflow-x: hidden;
}

.direct-chat-msg {
  margin-bottom: 10px;
}
.direct-chat-msg,
.direct-chat-text {
  display: block;
}
.direct-chat-msg:before,
.direct-chat-msg:after {
  content: " ";
  display: table;
}
.direct-chat-info {
  display: block;
  margin-bottom: 2px;
  font-size: 12px;
}
.direct-chat-name {
  font-weight: 600;
}
.pull-left {
  float: left;
}
.direct-chat-timestamp {
  color: #999;
}

.pull-right {
  float: right;
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.direct-chat-img {
  border-radius: 50%;
  float: left;
  width: 40px;
  height: 40px;
}
.direct-chat-text {
  border-radius: 5px;
  position: relative;
  padding: 5px 10px;
  background: #d2d6de;
  border: 1px solid #d2d6de;
  margin: 5px 0 0 50px;
  color: #444;
}
.direct-chat-msg,
.direct-chat-text {
  display: block;
}
.direct-chat-text:before {
  border-width: 6px;
  margin-top: -6px;
}
.direct-chat-text:after,
.direct-chat-text:before {
  position: absolute;
  right: 100%;
  top: 15px;
  border: solid transparent;
  border-right-color: #d2d6de;
  content: " ";
  height: 0;
  width: 0;
  pointer-events: none;
}
.direct-chat-text:after {
  border-width: 5px;
  margin-top: -5px;
}
.direct-chat-text:after,
.direct-chat-text:before {
  position: absolute;
  right: 100%;
  top: 15px;
  border: solid transparent;
  border-right-color: #d2d6de;
  content: " ";
  height: 0;
  width: 0;
  pointer-events: none;
}
.direct-chat-msg:after {
  clear: both;
}
.direct-chat-msg:before,
.direct-chat-msg:after {
  content: " ";
  display: table;
}
.custommodal.demo-panel .modal__window {
  width: 425px;
}
.right .direct-chat-img {
  float: right;
}
.right .direct-chat-text {
  margin-right: 50px;
  margin-left: 0;
}
.right .direct-chat-text:after,
.right .direct-chat-text:before {
  right: auto;
  left: 100%;
  border-right-color: transparent;
  border-left-color: #d2d6de;
}
.skiptranslate {
  display: none;
}
.goog-tooltip {
  display: none !important;
}
.goog-tooltip:hover {
  display: none !important;
}
#goog-gt-tt {
  display: none !important;
  top: 0px !important;
}

.goog-text-highlight {
  background-color: #f8fafb !important;
  box-shadow: 1px 1px 1px #f8fafb !important;
}

.se-pre-con {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #000000d6;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 250px;
}

.resulted .react-form-builder-toolbar {
  display: none;
}

.fc .fc-toolbar-title {
  font-size: calc(5vh - 6px) !important;
}
.page-header__title {
  font-size: calc(3vh - 2px) !important;
  margin-top: 10px;
}
@media only screen and (max-width: 575px) {
  /* .customcalendar .fc-view-harness-active {
    height: calc(100vh - 230px)!important;
} */
}

input[type="checkbox"]:after {
  line-height: 1.5em;
  content: "";
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  margin-top: -3px;
  margin-left: -3px;
  border: 1px solid #953e3e;
  border-radius: 0px;
  background: #fff;
  line-height: 1.3;
}

input[type="checkbox"]:checked:after {
  width: 1.2rem;
  height: 1.2rem;
  display: block;
  content: "\f00c";
  font-family: "FontAwesome";
  color: #7571f9;
  font-weight: 100;
  text-align: center;
  font-size: 12px;
}

.radio {
  margin: 16px 0;
  display: block;
  cursor: pointer;
}

.radio input {
  display: none;
}

.radio input + span {
  line-height: 22px;
  height: 22px;
  padding-left: 22px;
  display: block;
  position: relative;
}

.radio input + span:not(:empty) {
  padding-left: 30px;
}

.radio input + span:before,
.radio input + span:after {
  content: "";
  width: 22px;
  height: 22px;
  display: block;
  border-radius: 50%;
  left: 0;
  top: 0;
  position: absolute;
}

.radio input + span:before {
  background: #d1d7e3;
  transition: background 0.2s ease,
    transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
}

.radio input + span:after {
  background: #fff;
  width: 16px;
  height: 16px;
  left: 3px;
  top: 3px;
  transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
}

.radio input:checked + span:before {
  transform: scale(1.04);
  background: #007bff;
}

.radio input:checked + span:after {
  width: 10px;
  height: 10px;
  left: 6px;
  top: 6px;
  transition: transform 0.3s ease;
}

.radio:hover input + span:before {
  transform: scale(0.92);
}

.radio:hover input + span:after {
  transform: scale(0.74);
}

.radio:hover input:checked + span:after {
  width: 10px;
  height: 10px;
  left: 6px;
  top: 6px;
}
.fc-daygrid {
}
.fc-daygrid .fc-scrollgrid-liquid {
  height: 100% !important;
}
.fc .fc-scrollgrid-liquid {
  height: auto;
}

/*..................................................................................*/
#custom_cal .fc-toolbar-chunk {
  display: flex;
  gap: 8px;
}
#custom_cal .fc-today-button {
  margin-left: 0;
}
#custom_cal .fc-header-toolbar {
  gap: 5px;
}

/* @media (max-width: 768px){
#form-page .react-form-builder .react-form-builder-toolbar{
  margin-bottom: -40px !important;
}
} */
.gap-2 {
  gap: 25px;
}
.gap-1 {
  gap: 10px;
}
.btn-sm {
  /* width: 105px; */
  /* height: 40px; */
  height: 35px;
}
.btn {
  align-items: center;
}
.page-header {
  padding-bottom: 6px;
}
.css-dtbg0r .PrivateDatePickerToolbar-penIcon {
  position: relative;
  top: -38px !important;
}
.css-18osw6q-MuiGrid-root {
  display: flex !important;
}
.css-dtbg0r {
  display: flex;
  width: 100% !important;
  max-width: 100% !important;
}
.css-1xhj18k {
  display: grid !important;
}
.fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 2px 4px;
  color: #2c3e50;
}
a {
  color: #2c3e50;
}
/* .eRFgQw{
padding: 10px !important;
} */

/* .cHfMFj{
min-width: auto !important;
} */
.d-md-grid {
  display: grid !important;
}

/* .print-items {
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
}

.print-item {
  break-inside: avoid;
  page-break-after: auto;
}

.print-item:after {
  position: relative;
  display: block;   
  min-width: 100%;
  height: 10px;
  width: 100%;
  content: "";
} */

input[type="checkbox"]::after {
  line-height: 1.5em;
  content: "";
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  margin-top: -3px;
  margin-left: -3px;
  border: 2px solid #953e3e;
  border-radius: 0px;
  background: #fff;
  line-height: 1.3;
}
textarea {
  border: 2px solid #ccc;
}

/* .container, .container-fluid{
  padding-right: 14px !important;
  padding-left: 14px !important;
} */

#myProgress {
  background-color: #ddd;
}

#myBar {
  width: 10%;
  height: 30px;
  background-color: #3bc2ff;
  text-align: center;
  line-height: 30px;
  color: white;
}
.custom-loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #000000d6;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.pointer {
  cursor: pointer;
}

.mTable td {
  width: 300px;
  text-align: center;
}

.mTable tr {
  width: 300px;
}

.formTable td {
  margin: 10px;
}

.row0 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.row1 {
  display: flex;
  align-items: center;
}
.form-control {
  margin: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.print-items {
  background: #fff;
}
.table-fotter {
  bottom: 0px;
  left: 0px;
  width: 100%;
}

.print-items .dr {
}
.print-items .dr:last-of-type {
  page-break-after: avoid;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
    -ms-print-color-adjust: exact;
    margin: 0;
    print-color-adjust: exact;
  }

  .page-break-after {
    display: block;
    page-break-after: always;
    padding: 15px;
    border: 3px solid #ccc;
  }

  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }

  .page-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  div {
    break-inside: avoid !important;
  }
}

.page-footer {
  bottom: 0;
  width: 100%;
}

.page-footer,
.page-footer-space {
  height: 50px;
}

.pdf-text-area {
  min-height: 100px;
  max-height: auto;
  width: 100%;
  border: 3px solid #ccc;
  padding: 2px;
}

.spaceType {
  background-color: transparent !important;
}
@page {
  display: block;
  margin: 20px;
}

.footer {
  display: none;
}
.content {
  padding: 20px 0;
}

#pdf {
  position: relative;
  margin-bottom: 50px; /* Adjust the margin as needed */
}

@media print {
  body {
    border: 0;
    margin: 0;
  }
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }
  button {
    display: none;
  }

  .footer {
    position: fixed;
    bottom: 0;
    border-top: 1px solid #ddd;
    left: 0;
    right: 0;
    text-align: center;
    display: block;
    width: 100%;
    display: table-footer-group;
    margin-top: 10px;
    page-break-before: always;
  }
}
.inline-block-element {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 auto;
}
.pdf-signature-image {
  width: 250px;
  height: 250px;
}
.single-pdf-loader-style {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-break-after-item {
  display: block;
  break-after: always;
  page-break-after: always;
}

.page-break-before-item {
  display: block;
  break-before: always;
  page-break-before: always;
}
.custom-label-h{}
.custom-label-h p{ font-size: 16px !important; margin-bottom: 0 !important;}